.list {
  list-style-type: none;
  padding: 0;
}
.listItem {
  margin-bottom: 1rem;
}
.listItemDate {
  font-size: 0.8rem;
}
.listItemTime {
  font-size: 1.2rem;
  font-weight: bold;
}
